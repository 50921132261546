import * as Sentry from '@sentry/nextjs';
import sha1 from 'sha1';
import { v4 as uuidv4 } from 'uuid';

export function truncateWithEllipses(text: string, max: number): string {
    return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}

export function generateToken(length: number): string {
    // edit the token allowed characters
    const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
    const b = [];
    for (let i = 0; i < length; i += 1) {
        const j = Number((Math.random() * (a.length - 1)).toFixed(0));
        b[i] = a[j];
    }
    return b.join('');
}

export function generateUID(): string {
    return sha1(uuidv4() + uuidv4() + new Date().getTime());
}

export function isValidJSON(string: string, canFail = false): boolean {
    try {
        JSON.parse(string);
    } catch (err) {
        if (!canFail) {
            console.error(`error when parsing JSON: ${string}`);
            Sentry.captureException(err);
        }

        return false;
    }
    return true;
}

export async function copyToClipboard(valueToCopy: string): Promise<void> {
    await navigator.clipboard.writeText(valueToCopy);
}

export function removeHttp(url: string): string {
    return url.replace(/^https?:\/\//, '');
}

export function isCurrentBrowserFirefox(): boolean {
    return navigator.userAgent.search('Firefox') > -1;
}

export function isMainDomain(domain: string): boolean {
    return process.env.NEXT_PUBLIC_APP_URL.toLowerCase().includes(domain.toLowerCase());
}
export function isCloudfrontHealthCheck(host: string): boolean {
    // We need to check if the domain isn't the healthcheck from cloudfront.
    // regex find ip address
    const regexIpAddress = /(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/g;
    return regexIpAddress.test(host);
}
