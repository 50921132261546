import type { ReactElement, ReactNode } from 'react';
import { useState, createContext, useEffect, useMemo } from 'react';

import type { IOrderPayment } from '../features/order';
import { isValidJSON } from '../lib/helper/helper';

export interface IOrderContext {
    order?: IOrderPayment;
    updateOrder: (order: IOrderPayment) => void;
    removeOrder: () => void;
}

export const OrderContext = createContext<IOrderContext>({
    updateOrder: () => {},
    removeOrder: () => {},
});

const localStorageOrderKey = 'order';

export interface OrderProviderProps {
    children: ReactNode;
}

function OrderProvider({ children }: OrderProviderProps): ReactElement {
    const [order, setOrder] = useState<IOrderPayment | undefined>(undefined);

    function updateOrder(newOrder: IOrderPayment): void {
        localStorage.setItem(localStorageOrderKey, JSON.stringify(newOrder));
        setOrder(newOrder);
    }

    function removeOrder(): void {
        localStorage.removeItem(localStorageOrderKey);
        setOrder(undefined);
    }

    useEffect(() => {
        const localStorageOrder = localStorage.getItem(localStorageOrderKey);
        if (!localStorageOrder) {
            return;
        }
        if (!isValidJSON(localStorageOrder)) {
            return;
        }
        setOrder(JSON.parse(localStorageOrder));
    }, []);

    const orderValue = useMemo<IOrderContext>(() => ({ order, updateOrder, removeOrder }), [order]);

    return <OrderContext.Provider value={orderValue}>{children}</OrderContext.Provider>;
}

export default OrderProvider;
