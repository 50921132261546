import '../styles/globals.css';
import type { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import Head from 'next/head';

import OrderProvider from '../context/OrderContext';

export default function MyApp({
    Component,
    pageProps: { session, ...pageProps },
}: AppProps<{ session: Session; theme?: string }>): JSX.Element {
    return (
        <SessionProvider session={session}>
            <OrderProvider>
                <ThemeProvider attribute="class" forcedTheme={pageProps?.theme || undefined}>
                    <Head>
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=0"
                        />
                    </Head>
                    <Component {...pageProps} />
                </ThemeProvider>
            </OrderProvider>
        </SessionProvider>
    );
}
